<script setup>
import { computed, onMounted } from 'vue';
import { useUi } from '@/composables/use-ui';
import { useBillingMerchant } from '@/composables/use-billing';
import { useAccountInvitations } from '@/composables/use-account-invitation';
import MenuList from './list.vue';
import useGlobalSnack from '@/components/commons/global-snack/composables';

const { show } = useGlobalSnack();
const { windowHeight, windowWidth, toolbarHeightPx, mobileBreakpointPx } = useUi();
const isMobile = computed(() => windowWidth.value < mobileBreakpointPx);
const menuHeight = computed(() => windowHeight.value - toolbarHeightPx);
const { 
  config: merchantConfig, 
  loadMerchantConfig,
  generateUrlToDashboard,
} = useBillingMerchant();

// autoload
loadMerchantConfig();

const menu = computed(() => [
  {
    type: 'solo',
    name: 'Dashboard',
    linkTo: '/dashboard',
    icon: 'las la-icons',
  },
  {
    type: 'divider',
  },
  {
    type: 'group',
    group: 'FACILITY',
    menu: [
      {
        id: 'booking', // 'id' is used to identify the menu item
        name: 'Bookings',
        icon: 'las la-calendar-alt',
        linkTo: '/dashboard/bookings/today',
      },
      {
        name: 'Patients',
        icon: 'las la-user-injured',
        linkTo: '/dashboard/patients',
      },
      // {
      //   name: 'Active Encounters',
      //   icon: 'las la-book-medical',
      //   linkTo: '/dashboard/encounters/active',
      // },
      // {
      //   name: 'All Encounters',
      //   icon: 'las la-notes-medical',
      //   linkTo: '/dashboard/encounters/all',
      // },
      merchantConfig.value?.status === 'active' && {
        id: 'transactions',
        name: 'Transactions',
        icon: 'las la-file-invoice-dollar',
        iconAppend: 'las la-external-link-alt',
        handler: async () => {
          try {
            show(`Redirecting to billing dashboard`, { type: 'info' });
            const dashboard = await generateUrlToDashboard();
            window.open(dashboard.url, '_blank');
          } catch (error) {
            console.error(error);
            show(`Failed to load transactions. ${error.message}`, { type: 'error' });
          }
        },
      },
      // {
      //   name: 'Shared Health Records',
      //   icon: 'las la-notes-medical',
      //   linkTo: '/dashboard/shared',
      //   meta: {
      //     forPatient: true,
      //   },
      // },
      {
        name: 'Notifications',
        icon: 'las la-bell',
        linkTo: '/dashboard/notifs',
      },
    ].filter(Boolean),
  },
  // {
  //   type: 'divider',
  // },
  // {
  //   type: 'group',
  //   group: 'MODULES',
  //   menu: [
  //     {
  //       name: 'Billing',
  //       icon: 'las la-file-invoice-dollar',
  //       linkTo: '/modules/billing',
  //     }
  //   ]
  // },
]);

const bottomMenu = [
  {
    type: 'divider',
  },
  {
    type: 'solo',
    name: 'Settings',
    linkTo: '/settings/account/personal',
    icon: 'las la-cog',
  },
];

const {
  list: listInvitations,
  remainingInvites,
} = useAccountInvitations();

onMounted(() => {
  listInvitations();
});
</script>

<template>
  <div class="flex flex-col justify-between z-50 p-2 w-[240px] bg-white"
    :style="{ height: `${menuHeight}px`, 'margin-top': isMobile ? `${toolbarHeightPx}px` : '' }">

    <div class="flex flex-col gap-2">
      <menu-list :menu="menu" @close="$emit('close')" />
    </div>

    <div class="flex flex-col gap-2">
      <menu-list :menu="bottomMenu" @close="$emit('close')" />

      <!-- <div class="card rounded-xl border border-neutral-200 bg-neutral-100">
        <div class="p-4">
          <h4 class="font-normal mb-2 text-[#284720] text-sm">Invite Users</h4>
          <p class="mb-3 text-xs font-normal text-neutral-500">Parmazip is currently by INVITE ONLY. You still have {{
            remainingInvites }} invites.</p>
          <button class="btn btn-neutral btn-xs normal-case text-white rounded w-full p-1 text-sm font-normal"
            @click="$router.push('/dashboard/invite-users')">
            <span class="text-xs">Invite Now</span>
          </button>
        </div>
      </div> -->
    </div>
  </div>
</template>

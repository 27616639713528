<script setup>
import { ref, onMounted } from 'vue';
import { useAuthentication } from '@/composables/use-authentication';
import { useFacilities } from '@/composables/use-facilities';
import { useOnline } from '@vueuse/core';
import { useNotifsTailed } from '@/composables/use-notifs';
import UserMenu from '@/components/commons/user-menu';
import MainMenu from '@/components/main-menu';
import useGlobalSnack from '@/components/commons/global-snack/composables';
import GlobalSnackbar from '@/components/commons/global-snack';

const isOnline = useOnline();

const { getUserFacilities, facilities, getActiveFacility } = useFacilities();
const { show } = useGlobalSnack();

useNotifsTailed({
  onNewNotif: (notif) => {
    console.warn('New notif', notif);
    show(notif.title, { type: 'info' });
  },
});

const {
  doctorWelcomeOnboardingDone,
  updateOnboarding,
  init: initCurrentUser,
} = useAuthentication();

const blockerDialog = ref(false);

onMounted(() => init());

async function init() {
  await initCurrentUser();
  await getUserFacilities();
  await getActiveFacility();
  
  if (doctorWelcomeOnboardingDone.value === false) {
    welcomeDialog.value = true;
  }
}

const welcomeDialog = ref(false);
const welcomeLoading = ref(false);

async function onWelcomeAccepted () {
  try {
    welcomeLoading.value = true;
    await updateOnboarding('doctorOnboarding.welcome');
    welcomeDialog.value = false;
  } catch (e) {
    console.error('Something went wrong. Try again later.');
  } finally {
    welcomeLoading.value = false;
  }
}

const drawer = ref(false);
</script>

<template>
  <div data-theme="easyjoey">
    <dialog id="welcomeDialog" :class="`modal ${welcomeDialog ? 'modal-open' : ''}`">
      <form method="dialog" class="modal-box max-w-3xl">
        <div class="flex gap-4 p-8">
          <div class="flex-1 flex flex-col gap-4">
            <h1 class="text-xl text-neutral font-bold">Welcome to Parmazip</h1>
            <p class="text-neutral-400">
              We built Parmazip with a mission to make primary care more accessible to everyone, and for pharmacists like you to harness the power of the latest technologies available today that could make your practice less hard.  Starting with telepharmacy, we're envisioning on-demand virtual care to be a norm in every household.
            </p>
            <p class="text-neutral-400">
              As the pharmacist's role expands over time, we are dedicated to support you with the latest tools and experience a new era of pharmacy practice. With Parmazip, you are at the forefront of modern pharmacy practice.
            </p>
            <button type="button" class="btn btn-sm btn-neutral normal-case text-white w-[200px]"
              :disabled="welcomeLoading" :loading="welcomeLoading" @click="onWelcomeAccepted">
              Let's get started!
            </button>
          </div>
          <div class="flex-1 flex items-center gap-2">
            <template v-if="isOnline">
              <vue-3-lottie
                animationLink="https://firebasestorage.googleapis.com/v0/b/mc-v4-prod.appspot.com/o/lottie%2Feasyjoey%2Fonboarding-mini-encounter.json?alt=media&token=5666aa86-a4bb-4061-a91d-2eb097c11e65"
                hover :speed="0.2" :loop="false" />
            </template>
            <template v-else>
              <img class="w-full" src="../assets/images/easyjoey-encounter-art.png" alt="">
            </template>
          </div>
        </div>
      </form>
    </dialog>

    <global-snackbar />
    <!-- DIALOGS END -->

    <div class="drawer lg:drawer-open">
      <input v-model="drawer" id="dashboard-layout-drawer" type="checkbox" class="drawer-toggle" />

      <div class="drawer-content flex flex-col">

        <header
          class="w-full navbar flex border-solid border-1 border-b justify-between items-center sticky top-0 z-10 bg-white h-16">
          <div class="flex-none lg:hidden">
            <label for="dashboard-layout-drawer" aria-label="open sidebar" class="btn btn-square btn-ghost">
              <i class="las la-bars text-2xl"></i>
            </label>
          </div>
          <div class="flex-1">
            <!-- Search -->
          </div>
          <div class="flex-none">
            <user-menu />
          </div>
        </header>

        <div class="flex flex-col">
          <main>

            <NuxtPage />

          </main>
        </div>
      </div>

      <div class="drawer-side border-solid border-1 border-r">
        <label for="dashboard-layout-drawer" aria-label="close sidebar" class="drawer-overlay"></label>
        <header class="navbar flex p-2 justify-between items-center z-10 w-[240px] h-16 header">
          <div class="flex-1 px-2 mx-2">
            <!-- <img class="h-[30px] hover:cursor-pointer ml-2" src="../assets/images/easyjoey-logo.png" alt=""
              @click="$router.push('/dashboard')" /> -->
            <img class="h-[50px] hover:cursor-pointer ml-2" src="../assets/images/parmazip/logo-with-text.svg" alt=""
              @click="$router.push('/dashboard')" />
          </div>
        </header>

        <MainMenu @close="drawer = false;"/>
      </div>
    </div>
  </div>
  <global-snackbar />
</template>

import { toRefs, reactive, computed } from 'vue';
import { useSdk } from './use-mycure';
import { useAuthentication } from './use-authentication';
import { format } from 'date-fns';
const SERVICE_NAME = 'account-invitations';
const MAX_INVITATIONS = 5;

const state = reactive({
  invitations: [],
});

function mapInvitation (invitation) {
  return {
    ...invitation,
    createdAt: format(new Date(invitation.createdAt), 'yyyy-MM-dd'),
  };
}

export function useAccountInvitations () {
  const sdk = useSdk();

  const { checkUniqueIdentity } = useAuthentication();

  const pending = computed(() => state.invitations.filter(invitation => !invitation.acceptedAt));
  const accepted = computed(() => state.invitations.filter(invitation => invitation.acceptedAt));
  const remainingInvites = computed(() => MAX_INVITATIONS - state.invitations.length);
  const canInvite = computed(() => remainingInvites.value > 0);

  async function list () {
    const currentUser = await sdk?.currentUser();
    const uid = currentUser?.uid;

    const query = {
      type: 'account-referral',
      createdBy: uid,
      email: {
        $exists: true,
      },
    };
    const result = await sdk?.list(SERVICE_NAME, query);
    state.invitations = result.data.map(mapInvitation);
    return result.data;
  }

  async function get (email) {
    const query = {
      type: 'account-referral',
      email,
    };
    const result = await sdk?.get(SERVICE_NAME, query);
    return result;
  }

  async function create (email) {
    if (!canInvite.value) {
      throw new Error('Cannot invite more users.');
    }

    const existingInvitation = await get(email);

    if (existingInvitation) {
      throw new Error(`Cannont invite email. ${email} is already invited.`);
    }

    const isUnique = await checkUniqueIdentity(email);

    if (!isUnique) {
      throw new Error(`Cannont invite email. ${email} is already in use.`);
    }

    const data = {
      type: 'account-referral',
      email,
      acceptUrl: `${window.location.origin}/standalone/signup`,
    };

    const result = await sdk?.create(SERVICE_NAME, data);
    state.invitations.push(mapInvitation(result));
  };

  async function remove (id) {
    const result = await sdk?.delete(SERVICE_NAME, id);
    state.invitations = state.invitations.filter(invitation => invitation.id !== id);
    return result;
  }

  return {
    pending,
    accepted,
    remainingInvites,
    canInvite,
    ...toRefs(state),
    create,
    list,
    get,
    remove,
  };
}
